import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Lightbox from 'lightbox2';
import 'lightbox2/dist/css/lightbox.min.css';
// import 'lightbox2/dist/js/lightbox.js';

export default function Portfolio() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
        Lightbox.option({
          resizeDuration: 200,
          fadeDuration: 600,
          imageFadeDuration: 600,
          wrapAround: true
        });
      }, []);
  return (
    <section className="portfolio-area page-section scroll-to-page" id="portfolio">
        <div className="custom-container">
            <div className="portfolio-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-grip-vertical"></i> Portfolio
                    </h4>
                    <h1 className="scroll-animation">Featured <span>Projects</span></h1>
                </div>

                <div className="row portfolio-items">
                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-full">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/linkedhut.com.png" data-lightbox="example-1">
                                    <img src="../assets/images/linkedhut.com.png" alt="Portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://nextjs.org">Next.js</a>
                                    </li>
                                    <li>
                                        <a href="https://tailwindcss.com">Tailwind CSS</a>
                                    </li>
                                    <li>
                                        <a href="https://nodejs.org">Node.js</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a href="https://www.linkedhut.com">Web app: linkedhut.com</a></h2>
                        </div>
                    </div>

                    <div className="col-md-6 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/ObitLive.png" data-lightbox="example-1">
                                    <img src="../assets/images/ObitLive.png" alt="Portfolio"/>
                                </a>

                                <img src="../assets/images/ObitLive.png" alt="Portfolio"/>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://developer.apple.com/swift">Swift</a>
                                    </li>
                                    <li>
                                        <a href="https://developer.apple.com/xcode/swiftui">SwiftUI</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a href="https://apps.apple.com/us/app/obit-live/id1519512858">Live Streaming Memorial App</a></h2>
                        </div>
                    </div>

                    <div className="col-md-6 scroll-animation"  data-aos='fade-left'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/NABEConnect.png" data-lightbox="example-1">
                                    <img src="../assets/images/NABEConnect.png" alt="Portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://developer.apple.com/swift">Swift</a>
                                    </li>
                                    <li>
                                        <a href="https://developer.apple.com/xcode/swiftui">SwiftUI</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a href="https://apps.apple.com/us/app/nabe-connect/id1466750048?platform=iphone">NABE Connect iOS app</a></h2>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/Pokerrrr2.png" data-lightbox="example-1">
                                    <img src="../assets/images/Pokerrrr2.png" alt="portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://unity.com">Unity</a>
                                    </li>
                                    <li>
                                        <a href="https://cplusplus.com">C++</a>
                                    </li>
                                    <li>
                                        <a href="https://learn.microsoft.com/en-us/dotnet/csharp">C#</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a href="https://apps.apple.com/us/app/pokerrrr-2-holdem-ofc-rummy/id592081067">Pokerrrr 2: Pocker Game</a></h2>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/marvinxr.png" data-lightbox="marvinxr">
                                    <img src="../assets/images/marvinxr.png" alt="portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://unity.com">Unity</a>
                                    </li>
                                    <li>
                                        <a href="https://learn.microsoft.com/en-us/dotnet/csharp">C#</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a href="https://www.marvinxr.com">MarvinXR</a></h2>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/WahooSYSTM.png" data-lightbox="example-1">
                                    <img src="../assets/images/WahooSYSTM.png" alt="portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://reactnative.dev">React Native</a>
                                    </li>
                                    <li>
                                        <a href="https://nodejs.org/en">Node.js</a>
                                    </li>
                                    <li>
                                        <a href="https://firebase.google.com">Firebase</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a href="https://apps.apple.com/us/app/wahoo-systm/id1501733842">Wahoo SYSTM Fitness App</a></h2>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/circledin.png" data-lightbox="circledin">
                                    <img src="../assets/images/circledin.png" alt="portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://www.python.org">Python</a>
                                    </li>
                                    <li>
                                        <a href="https://react.dev">React</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a href="https://circledin.com">Employee Management System</a></h2>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/Distribution System.png" data-lightbox="Distribution System">
                                    <img src="../assets/images/Distribution System.png" alt="portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://www.python.org">Python</a>
                                    </li>
                                    <li>
                                        <a href="https://www.djangoproject.com">Django</a>
                                    </li>
                                    <li>
                                        <a href="https://react.dev">React</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a href="../assets/images/Distribution System.png">Distribution System</a></h2>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/dashboard2.png" data-lightbox="dashboard2">
                                    <img src="../assets/images/dashboard2.png" alt="portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://www.ruby-lang.org/en">Ruby</a>
                                    </li>
                                    <li>
                                        <a href="https://react.dev">React</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a href="https://drive.google.com/file/d/1L--GaxZD1verglG7JG0lnspxcmSlugfV/view?usp=sharing">Employee Management System</a></h2>
                        </div>
                    </div>

                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/dashboard1.png" data-lightbox="dashboard1">
                                    <img src="../assets/images/dashboard1.png" alt="portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://react.dev">React</a>
                                    </li>
                                    <li>
                                        <a href="https://nodejs.org/en">Node.js</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a href="../assets/images/dashboard1.png">SocialMedia Management Dashboard</a></h2>
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
    </section>
  )
}
