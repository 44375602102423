import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Resume() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="resume-area page-section scroll-to-page" id="resume">
        <div className="custom-container">
            <div className="resume-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-briefcase"></i><a href="https://www.canva.com/design/DAGCBtZ5KmY/Seon-P6DG6sdziv-9kbifw/edit"> Resume </a>
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Education & <span>Experience</span></h1>
                </div>

                <div className="resume-timeline">
                    <div className="item scroll-animation" data-aos='fade-right'>
                        <span className="date">2016 - Present</span>
                        <h2>Senior Software Developer</h2>
                        <p>Freelancer</p>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-left'>
                        <span className="date">2021 - 2023</span>
                        <h2>Senior Mobile App Developer</h2>
                        <p>Nickelfox Technologies </p>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-left'>
                        <span className="date">2018 - 2021</span>
                        <h2>Senior Full Stack Developer</h2>
                        <p> USM Business Systems </p>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-left'>
                        <span className="date">2016 - 2019</span>
                        <h2>Lead Developer</h2>
                        <p>kurieta</p>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-left'>
                        <span className="date">2014 - 2017</span>
                        <h2>Senior Desktop and Backend Developer</h2>
                        <p>Simform</p>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-right'>
                        <span className="date">2009 - 2014</span>
                        <h2>Master Degree of Computer Engineering</h2>
                        <p>Indiana State University - Scott College of Business</p>
                    </div>
                </div>

            </div>
        </div>
    </section>
  )
}
