import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function About() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="about-area page-section scroll-to-page" id="about">
        <div className="custom-container">
            <div className="about-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="lar la-user"></i> About
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Why Hire Me?<br/></h1>
                </div>
                <p className="scroll-animation" data-aos='fade-up'>Since beginning my journey as a freelance developer nearly 10 years ago, I've done remote work for agencies, consulted for startups, and collaborated with talented people to create digital products for both business and consumer use. <br></br> </p>
                <p className="scroll-animation" data-aos='fade-up'>🎯 Client-Centric Approach: Tailoring solutions to your unique requirements.</p>
                <p className="scroll-animation" data-aos='fade-up'>🚀 Quality and Efficiency: Implementing Agile and DevOps for top-tier software delivery.</p>
                <p className="scroll-animation" data-aos='fade-up'>🌍 Global Experience: Serving a diverse international clientele.</p>
                <p className="scroll-animation" data-aos='fade-up'>💡 Innovative Solutions: Continuously updating my skill set with the most trending technologies.</p>
            </div>
        </div>
    </section>
  )
}
