import React from 'react'

export default function Lsb() {
  return (
        <div className="left-sidebar">
            <div className="sidebar-header d-flex align-items-center justify-content-between">
                <img src="./assets/images/logo.png" alt="Logo"/>
                <span className="designation"> Senior Software Developer</span>
            </div>
            <img className="me" src="./assets/images/me.jpg" alt="Me"/>
            <h2 className="email">hajimedaniel@gmail.com</h2>
            <h2 className="address">Base in Sellersburg, IN</h2>
            <p className="copyright">&copy; 2015 Daniel. All Rights Reserved</p>
            <ul className="social-profile d-flex align-items-center flex-wrap justify-content-center">
                <li>
                    <a href="https://www.linkedin.com/in/daniel-hajime-43b9b52b8"><i className="lab la-linkedin"></i></a>
                </li>
                <li>
                    <a href="https://github.com/danhaji3"><i className="lab la-github"></i></a>
                </li>
            </ul>
            <a href="#" className="theme-btn">
                <i className="las la-envelope"></i> Hire Me!
            </a>
        </div>
  )
}   
