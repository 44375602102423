import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Service() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="services-area page-section scroll-to-page" id="services">
        <div className="custom-container">
            <div className="services-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-stream"></i> Services
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>My <span>Specializations</span></h1>
                </div>

                <div className="services-items">
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-bezier-curve"></i>
                        <h2>Website Development</h2>
                        <p>I create digital products with unique ideas for various industries with React.js, Vue.js, Node.js, Ruby on Rails, ASP.NET, C#</p>
                        <span className="projects">105 Projects</span>
                    </div>
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-code"></i>
                        <h2>Mobile App Development</h2>
                        <p>I build beautiful mobile apps with React Native, Swift, SwiftUI, Java, Kotlin, Flutter, Xamarin, Node.js, Python, C++</p>
                        <span className="projects">145 Projects</span>
                    </div>
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-bezier-curve"></i>
                        <h2>Desktop Apps</h2>
                        <p>I develop custom desktop application for Mac and Windows with C/C++, Electron.js, C#, ASP.NET, Delphi, Python</p>
                        <span className="projects">32 Projects</span>
                    </div>
                </div>    
            </div>
        </div>
    </section>
  )
}
