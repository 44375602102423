import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Herosec() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="hero-section page-section scroll-to-page" id="home">
        <div className="custom-container">
            <div className="hero-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-animation="fade_from_bottom" data-aos='fade-up'>
                        <i className="las la-home"></i> Introduce
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>My name is <span>Daniel</span>, and I am a Senior Software Developer</h1>
                </div>
                <p className="scroll-animation" data-aos='fade-up'>As a Senior Software Developer, I work with clients worldwide to bring their ideas to life with the latest and most efficient technologies. I have over 10 years of experience in developing web and mobile applications for various domains and platforms, using JavaScript frameworks, Model-View-Controller (MVC) architecture, React Native, Flutter, Ionic, Objective-C, C/C++, QT Framework, Java, Kotlin, Swift, and SwiftUI. <br></br> </p> 
                <p className="scroll-animation" data-aos='fade-up'>My mission is to deliver innovative and user-friendly solutions that meet the needs and expectations of my clients. I enjoy collaborating with other developers, designers, and stakeholders, and I always seek to improve my skills and learn new tools and techniques. I am passionate about creating products that are fast, reliable, and scalable, and that provide a positive and engaging user experience.</p>
                <a href="#portfolio" 
                    className="go-to-project-btn scroll-to scroll-animation" 
                     data-aos='fade-up'>
                    <img src="../assets/images/round-text.png" alt="Rounded Text"/>
                    <i className="las la-arrow-down"></i>
                </a>    
                <div className="facts d-flex">
                    <div className="left scroll-animation" data-aos='fade-right'>
                        <h1>10+</h1>
                        <p>Years of <br/>Experience</p>
                    </div>
                    <div className="right scroll-animation" data-aos='fade-left'>
                        <h1>282+</h1>
                        <p>projects completed on <br/>15 countries</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
